<template>
  <b-card no-body>

    <b-card-header>
      <h4>Hasil Perolehan Susu Mentah</h4>
    </b-card-header>

    <b-card-body class="p-1">
      <app-echart-stacked-area :option-data="option" />
    </b-card-body>

  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    BCard,
    BCardHeader, 
    BCardBody,
    AppEchartStackedArea,
  },
  data() {
    return {
      option: {
        xAxisData: [
          '1/11', '2/11', '3/11', '4/11', '5/11', '6/11', '7/11', 
          '8/11', '9/11', '10/11', '11/11', '12/11', '13/11', '14/11',
          '15/11', '16/11', '17/11', '18/11', '19/11', '20/11', '21/11', 
          '22/11', '23/11', '24/11', '25/11', '26/11', '27/11', '28/11',
          '29/11', '30/11', '31/11',],
        series: [
          {
            name: 'Volume Produksi (liter)',
            type: 'line',
            stack: 'Total',
            areaStyle: {
              color: '#0E9CFF',
            },
            color: '#0E9CFF',
            showSymbol: true,
            smooth: 0,
            lineStyle: {
              width: 2,
            },
            data: [
              100, 200, 300, 400, 500, 400, 300, 
              300, 200, 300, 400, 500, 500, 300, 
              500, 350, 400, 400, 500, 400, 300, 
              400, 200, 300, 400, 500, 400, 300, 
              250, 250,
            ],
          },
        ],
      },
    }
  },
}
</script>
