<template>
  <b-sidebar
    id="add-new-raw-milk-sidebar"
    :visible="isUpdateRawMilkStockSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-update-raw-milk-stock-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Catat Penggunaan Susu Mentah
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm">
          

          <validation-provider
            #default="validationContext"
            name="Volume Keluar"
            rules="required"
          >
            <b-form-group
              label="Volume (ml)"
              label-for="volume"
            >
              <b-form-input
                id="volume"
                v-model="rawMilkData.volume"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="(ml, jika ada desimal gunakan titik, misal : 0.5)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tanggal Penggunaan -->
          <validation-provider
            #default="validationContext"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="date"
            >
              <b-form-datepicker
                id="date"
                placeholder="Choose a date"
                v-model="rawMilkData.date_used"
                :state="getValidationState(validationContext)"
                trim
              />
              
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Deskripsi -->
          <validation-provider
          #default="validationContext"
          name="Deskripsi">

          <b-form-group
            label="Deskripsi"
            label-for="description"
            :state="getValidationState(validationContext)"
          >
            <b-form-input
              id="description"
              v-model="rawMilkData.description"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Deskripsi hasil perah"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="$can('create', 'raw_milk')"
            >
              Perbarui
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Batal
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput,BFormRadioGroup, BFormInvalidFeedback, BButton, BFormDatepicker, BFormTimepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUpdateRawMilkStockSidebarActive',
    event: 'update:is-update-raw-milk-stock-sidebar-active',
  },
  props: {
    isUpdateRawMilkStockSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      milkConditionOptions: [],
      femaleGoatOptions: [],
    }
  },
  setup(props, { emit }) {

    // use Toast
    const toast = useToast()
    // setup json post goat data
    const blankRawMilkData = {
      volume: null,
      date_used: '',
      description: '',
    }

    //set default birth date is today
    const today = new Date();
    blankRawMilkData.date_used = today;
    
    // define rawMilkData from JSON data to ref variable
    const rawMilkData = ref(JSON.parse(JSON.stringify(blankRawMilkData)))

    // when click Cancle button
    const resetRawMilkData = () => {
      rawMilkData.value = JSON.parse(JSON.stringify(blankRawMilkData))
    }

    // when click Add button
    const onSubmit = () => {
    
      store.dispatch('app-raw-milks/substractRawMilk', rawMilkData.value)
      .then((response) => {
        if(response.data.data.status == 417){
          toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal mencatat hasil perah susu',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        emit('refetch-data-stock')
        emit('update:is-update-raw-milk-stock-sidebar-active', false)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil mencatat penggunaan susu',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

      })
  }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetRawMilkData)

    return {
      rawMilkData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  created() {
    // get female goats
    this.$http.get('/animals/female').then(response => {
      const femaleGoats = response.data.data

      femaleGoats.forEach(goat => {
        this.femaleGoatOptions.push(
          {
            label: goat.name, 
            value:  goat.id
          }
        )
      });
    })
    // get female goats
    this.$http.get('/milk/conditions').then(response => {
      const milkConditions = response.data.data

      milkConditions.forEach(condition => {
        this.milkConditionOptions.push(
          {
            label: condition.name, 
            value:  condition.id
          }
        )
      });
    })
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-goat-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
