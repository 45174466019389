import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRawMilksList() {
  // Use toast
  const toast = useToast()

  const refGoatsRawMilkSummary = ref(null)

  // Table Handlers
  const tableColumnsRawMilkSummary = [
    { key: 'nama_kambing', sortable: false },
    { key: 'terakhir_diperah', sortable: false },
    { key: 'kondisi_susu_terakhir', sortable: false },
    { key: 'total_volume', sortable: false },
    { key: 'actions' },
  ]
  const perPageRawMilkSummary = ref(10)
  const totalGoatsRawMilkSummary = ref(0)
  const currentPageRawMilkSummary = ref(1)
  const perPageOptionsRawMilkSumarry = [10, 25, 50, 100]
  const searchQueryRawMilkSummary = ref('')
  const sortByRawMilkSummary = ref('id')
  const isSortDirDescRawMilkSummary = ref(true)

  const dataMetaRawMilkSummary = computed(() => {
    const localItemsCount = refGoatsRawMilkSummary.value ? refGoatsRawMilkSummary.value.localItems.length : 0
    return {
      from: perPageRawMilkSummary.value * (currentPageRawMilkSummary.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageRawMilkSummary.value * (currentPageRawMilkSummary.value - 1) + localItemsCount,
      of: totalGoatsRawMilkSummary.value,
    }
  })

  const refecthRawMilkSummaary = () => {
    refGoatsRawMilkSummary.value.refresh()
  }

  watch([currentPageRawMilkSummary, perPageRawMilkSummary, searchQueryRawMilkSummary,], () => {
    refecthRawMilkSummaary()
  })

  const fetchGoatsRawMilkSummary = (ctx, callback) => {
    store
      .dispatch('app-raw-milks/fetchGoatsRawMilkSummary')
      .then(response => {
        const data = response.data.data
        const { total } = response.data.data
        
        callback(data)
        totalGoatsRawMilkSummary.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Goats Raw Milks Summary',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchGoatsRawMilkSummary,
    tableColumnsRawMilkSummary,
    perPageRawMilkSummary,
    currentPageRawMilkSummary,
    totalGoatsRawMilkSummary,
    dataMetaRawMilkSummary,
    perPageOptionsRawMilkSumarry,
    searchQueryRawMilkSummary,
    sortByRawMilkSummary,
    isSortDirDescRawMilkSummary,
    refGoatsRawMilkSummary,

    refecthRawMilkSummaary,

  }
}
