<template>
  <b-card
    no-body
    class="card-employee-task border-primary"
  >
    <b-card-body>
      <b-row align-h="between">
        <b-col cols="10">
          <b-card-title> Stok Susu Mentah </b-card-title>
        </b-col>
        <b-col cols="2">
          <b-link :to="{name: 'rawMilksStockHistory'}">
            <feather-icon icon="ExternalLinkIcon" title="Lihat history" size="16"/>
          </b-link>
        </b-col>
      </b-row>

      <div class="mb-2"> 
        <h5 class="font-weight-normal">Stok Tersedia</h5>
        <h3 class="font-weight-normal">{{stock_remaining + ' ml'}}</h3>
      </div>
      
      <div class="mb-2"> 
        <h5 class="text-muted">Total Perolehan</h5>
        <h3 class="font-weight-normal">{{total_stock + ' ml'}}</h3>
      </div>

      <div class="mb-2"> 
        <h5 class="text-muted">Total Diolah</h5>
        <h3 class="font-weight-normal">{{stock_out + ' ml'}}</h3>
      </div>

      <div class="mb-1" > 
        <b-button
          variant="primary"
          block
          @click="$emit('update:is-update-raw-milk-stock-sidebar-active', true)"
        >
          <span class="text-nowrap">Catat Pengeluaran</span>
        </b-button>
      </div>
      
      <span> 
        <small class="text-muted mb-0">Terakhir diperbaruhi: {{ last_updated_at }}</small>
      </span>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BLink, BCol, BButton, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import axios from '@axios'

import { onMounted, ref,  } from '@vue/composition-api';
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BRow,
    BLink,
    BCol,
    BButton,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    VueApexCharts,
  },
  model: {
    prop: 'isUpdateRawMilkStockSidebarActive',
    event: 'update:is-update-raw-milk-stock-sidebar-active',
  },
  props: {
    isUpdateRawMilkStockSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(){
    const total_stock = ref(0)
    const stock_out = ref(0)
    const stock_remaining = ref(0)
    const last_updated_at = ref('')

    onMounted(() => {
        axios.get('/milk/raw/stock').then(response => {
        const data = response.data.data[0]

        total_stock.value = data.total_raw_milk
        stock_out.value = data.total_raw_milk_used
        stock_remaining.value = data.stock_remaining
        last_updated_at.value = data.last_updated_at

      });
    })

    return { total_stock, stock_out, stock_remaining, last_updated_at }
  },
}
/* eslint-disable global-require */
</script>
